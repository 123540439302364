export const elName = "p-edit-form";

const template = document.createElement("template");
template.innerHTML = `
<style>
:host(.editing) #edit {
    display: none;
}

:host(:not(.editing)) #cancel {
    display: none;
}

:host(:not(.editing)) #save {
    display: none;
}

form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    & .button {
        width: fit-content;
        margin: 0;
    }
}

::slotted(.form__group) {
    margin-bottom: 0 !important;
}

.buttons {
    align-self: end;
    display: flex;
    gap: 16px;
}
</style>
<link href="${(window as any)["common/generated/common-bundle.css.ftl"]}" rel="stylesheet"/>
<form>
    <slot></slot>
    <div class="message error" hidden>
        Leider können wir deine Anfrage zur Zeit nicht bearbeiten.
        Bitte lade die Seite neu, oder versuch es später noch einmal.
    </div>
    <div class="buttons">
        <button id="edit" type="button" class="button button-secondary button-size-m icon icon-edit">
            Bearbeiten
        </button>
        <button id="cancel" type="button" class="button button-secondary button-size-m icon icon-cancel">
            Abbrechen
        </button>
        <button id="save" type="button" class="button button-primary button-size-m icon icon-save">
            Speichern
        </button>
    </div>
</form>
`;

export class EditForm extends HTMLElement {
  static observedAttributes = ["editing"];
  private editing: boolean = false;
  private inputs: HTMLInputElement[] = [];
  private inputErrors: HTMLInputElement[] = [];
  private generalErrorEl: HTMLDivElement;

  constructor() {
    super();
    this.attachShadow({mode: "open"});
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.generalErrorEl = this.shadowRoot.querySelector(".message.error");
  }

  connectedCallback() {
    this.shadowRoot.querySelector("#edit").addEventListener("click", () => {
      this.editing = true;
      this.setAttribute("editing", "");
      this.updateUI();
    });
    this.shadowRoot.querySelector("#save").addEventListener("click", () => {
      this.editing = false;
      this.removeAttribute("editing");
      this.updateUI();
      this.dispatchEvent(
        new CustomEvent("save", {
          detail: this.inputs.reduce((acc, val) => {
            acc[val.name] = val.value;
            return acc;
          }, {}),
        }),
      );
    });
    this.shadowRoot.querySelector("#cancel").addEventListener("click", () => {
      this.editing = false;
      this.removeAttribute("editing");
      this.generalError = false;
      this.updateUI();
      this.dispatchEvent(new CustomEvent("cancel"));
    });
    this.querySelectorAll("input").forEach((input) => {
      this.inputs.push(input)
    });

    this.updateUI();
  }

  attributeChangedCallback(name: string, oldValue?: string, newValue?: string) {
    if (name === "editing") {
      this.editing = newValue !== null;
      this.updateUI();
    }
  }

  set generalError(value: boolean) {
    this.generalErrorEl.hidden = !value;
  }

  updateUI() {
    this.classList.toggle("editing", this.editing);
    for (const input of this.inputs) {
      input.disabled = !this.editing;
      input.classList.toggle("form__input--disabled", !this.editing);
    }
  }
}

customElements.define(elName, EditForm);
